exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ai-js": () => import("./../../../src/pages/AI.js" /* webpackChunkName: "component---src-pages-ai-js" */),
  "component---src-pages-ai-privacy-js": () => import("./../../../src/pages/AIPrivacy.js" /* webpackChunkName: "component---src-pages-ai-privacy-js" */),
  "component---src-pages-ai-terms-js": () => import("./../../../src/pages/AITerms.js" /* webpackChunkName: "component---src-pages-ai-terms-js" */),
  "component---src-pages-cookie-js": () => import("./../../../src/pages/Cookie.js" /* webpackChunkName: "component---src-pages-cookie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-site-privacy-js": () => import("./../../../src/pages/SitePrivacy.js" /* webpackChunkName: "component---src-pages-site-privacy-js" */),
  "component---src-pages-site-terms-js": () => import("./../../../src/pages/SiteTerms.js" /* webpackChunkName: "component---src-pages-site-terms-js" */),
  "component---src-pages-sync-js": () => import("./../../../src/pages/Sync.js" /* webpackChunkName: "component---src-pages-sync-js" */),
  "component---src-pages-sync-privacy-js": () => import("./../../../src/pages/SyncPrivacy.js" /* webpackChunkName: "component---src-pages-sync-privacy-js" */),
  "component---src-pages-sync-terms-js": () => import("./../../../src/pages/SyncTerms.js" /* webpackChunkName: "component---src-pages-sync-terms-js" */)
}

